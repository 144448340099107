import React from "react";
import { Helmet } from "react-helmet";

function Layout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Helmet>
        <title>FIXR CMS</title>
        <link rel="icon" href="/favicon.png" type="image/png" />
      </Helmet>
      {children}
    </>
  );
}

export default Layout;
